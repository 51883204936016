export interface Scorm1_2 {
    LMSInitialize(arg: string): boolean;
    LMSFinish(arg: string): boolean;
    LMSGetValue(element: string ): string;
    LMSSetValue(element: string, value : string): string;
    LMSCommit(arg: string): boolean;
    LMSGetLastError(): string;
    LMSGetErrorString(errorCode: string ): string
    LMSGetDiagnostic(errorCode: string ): string
}

export enum Status {
    Incomplete = 'incomplete',
    Completed = 'completed',
    Passed = 'passed',
    Failed = 'failed'
}

export enum Entry {
    Init = "ab-initio",
    Resume = 'resume'
}

export const cmi = {
    core: {
        entry: "cmi.core.entry",
        student_name: "cmi.core.student_name",
        lesson_status: "cmi.core.lesson_status",
        lesson_location: "cmi.core.lesson_location"
    }
}