import {Component, OnInit} from '@angular/core';
import {Status} from './scorm/scorm.model';
import {ScormService} from './scorm/scorm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  color = document.querySelector('#course-data-color')?.innerHTML;

  constructor(private scormService: ScormService) {
  }

  ngOnInit(): void {
    this.scormService.init();
    window.onbeforeunload = () => this.scormService.finish();
  }

  complete(): void {
    this.scormService.setStatus(Status.Completed);
  }

  fail(): void {
    this.scormService.setStatus(Status.Failed);
  }

  pass(): void {
    this.scormService.setStatus(Status.Passed);
  }
}
