<!--<h2>Adme Angular Course Client</h2>

<h3>Set Status</h3>

<button (click)="complete()">
  completed
</button>

<button (click)="pass()">
  passed
</button>

<button (click)="fail()">
  failed
</button>-->
<router-outlet></router-outlet>
