import { Injectable } from "@angular/core";
import { cmi, Entry, Scorm1_2, Status } from "./scorm.model";

@Injectable({
    providedIn: 'root'
})
export class ScormService {

    api: Scorm1_2 | undefined = undefined;

    constructor() {
        let win:any = window;
        let attempt = 0;
        while (win) {
            attempt += 1;
            if (win.API) {
                this.api = win.API;
                return;
            }
            if (win.parent && win.parent !== win) {
                win = win.parent;
                continue;
            }
            if (window.opener && typeof window.opener != "undefined") {
                win = window.opener;
                continue;
            }
            if (attempt >= 200) {
                break;
            }
        }
    }

    init() {
        if (!this.api) return;
        this.api.LMSInitialize("");
        const status = this.api.LMSGetValue(cmi.core.entry);
        if (status === Entry.Init) {
            this.api.LMSSetValue(cmi.core.lesson_status, Status.Incomplete);
        }
    }

    finish() {
        this.api?.LMSFinish("");
    }

    setStatus(status: Status) {
        this.api?.LMSSetValue(cmi.core.lesson_status, status);
    }

}